import React, { useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Sphere, Html, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import ThreeSixtyLobby from "../assets/360Pics/360-LGR-LGR.jpg"
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom


interface HotspotProps {
  position: [number, number, number];
  onClick: () => void;
  label: string;
}

const Hotspot: React.FC<HotspotProps> = ({ position, onClick, label }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      onClick();
    }, 500); // Match this with the animation duration
  };

  return (
    <Html position={position}>
      <button
        className={`bg-white text-black p-2 rounded-full shadow-lg hover:bg-gray-200 transition duration-300 ${isAnimating ? 'hotspot-animation' : ''}`}
        onClick={handleClick}
      >
        {label}
      </button>
    </Html>
  );
};

const ThreeSixtyLGR: React.FC = () => {
  const [currentImage, setCurrentImage] = useState<string>(ThreeSixtyLobby);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false); // State to control video display
  const [videoSrc, setVideoSrc] = useState<string>(''); // State for the video source
  const navigate = useNavigate(); // Use the useNavigate hook for navigation

  // Load the texture dynamically based on the current image state
  const texture = new THREE.TextureLoader().load(currentImage);

  const handleHotspotClick = (image: string, path: string, video: string) => {
    setIsTransitioning(true); // Start the zoom-in transition
    setCurrentImage(image);
    setVideoSrc(video); // Set the video source
    setShowVideo(true); // Display the video
    setTimeout(() => {
      navigate(path); // Navigate to the new page
    }, 500); // Match this with your transition duration
  };

  return (
    <div className={`w-full h-screen bg-black transition-transform duration-500 ${isTransitioning ? 'scale-out' : 'scale-in'}`}>
      <Canvas>
        <Sphere args={[500, 60, 40]} scale={[-1, 1, 1]}>
          <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
        </Sphere>

        {/* Hotspots with navigation */}
        <Hotspot
          position={[-770, 50, -50]}
          label="LOBBY"
          onClick={() => handleHotspotClick(ThreeSixtyLobby, '/360-LGR-Lobby', '../assets/360Pics/360-Trans/360Trans.mp4')}
        />

        <OrbitControls enableZoom={false} />
      </Canvas>

      {/* Display video modal */}
      {showVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="relative w-full max-w-4xl">
            <video
              className="w-full rounded-lg shadow-lg"
              controls
              autoPlay
              onEnded={() => setShowVideo(false)} // Close video when it ends
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button
              className="absolute top-4 right-4 text-white text-xl"
              onClick={() => setShowVideo(false)} // Close video when clicked
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThreeSixtyLGR;
