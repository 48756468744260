import React, { useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Sphere, Html, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import ThreeSixtyLGRPoolOne from "../assets/360Pics/360-LGR-POOL-1.jpg"
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

interface HotspotProps {
  position: [number, number, number];
  onClick: () => void;
  label: string;
}

const Hotspot: React.FC<HotspotProps> = ({ position, onClick, label }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      onClick();
    }, 500); // Match this with the animation duration
  };

  return (
    <Html position={position}>
      <button
        className={`bg-white text-black p-2 rounded-full shadow-lg hover:bg-gray-200 transition duration-300 ${isAnimating ? 'hotspot-animation' : ''}`}
        onClick={handleClick}
      >
        {label}
      </button>
    </Html>
  );
};

const ThreeSixtyLGRPool1: React.FC = () => {
  const [currentImage, setCurrentImage] = useState<string>(ThreeSixtyLGRPoolOne);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const navigate = useNavigate(); // Use the useNavigate hook for navigation

  // Load the texture dynamically based on the current image state
  const texture = new THREE.TextureLoader().load(currentImage);

  const handleHotspotClick = (image: string, path: string) => {
    setIsTransitioning(true); // Start the zoom-in transition
    setCurrentImage(image);
    setTimeout(() => {
      navigate(path); // Navigate to the new page
    }, 500); // Match this with your transition duration
  };

  return (
    <div className={`w-full h-screen bg-black transition-transform duration-500 ${isTransitioning ? 'scale-out' : 'scale-in'}`}>
      <Canvas>
        <Sphere args={[500, 60, 40]} scale={[-1, 1, 1]}>
          <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
        </Sphere>

        {/* Hotspots with navigation */}

        <Hotspot
          position={[20, -25, -50]}
          label="LOBBY EXTENSION"
          onClick={() => (window.location.href = '/360-LGR-Lobby-Ext')}
        />

        <OrbitControls enableZoom={false} />
      </Canvas>
    </div>
  );
};

export default ThreeSixtyLGRPool1